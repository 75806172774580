import dayjs from "dayjs";
import { api } from "../../api/api";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import Drawer from "../../components/drawer";
import { useSecurity } from "../../hooks/useSecurity";
import { PageHeader } from "../../components/page-header";
import { ActionGrid } from "../../components/action-grid";
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { SearchForm } from "../../forms/associateMonthlyBilling/SearchForm";
import { CreateOrEditForm } from "../../forms/associateMonthlyBilling/CreateOrEditForm";
import { DownloadOutlined, PrinterFilled } from "@ant-design/icons";
import { Container } from "../../components/container";
import { MdEmail } from "react-icons/md";

export const AssociateMonthlyBilling: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [roles, setRoles] = useState<any>();
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [associates, setAssociates] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadloading, setDownloadloading] = useState(false);
  const [associateMonthlyBilling, setAssociateMonthlyBilling] = useState<any>(
    {}
  );
  const [associateMonthlyBillings, setAssociateMonthlyBillings] = useState<
    any[]
  >([]);
  const { getPermissions } = useSecurity();

  const getData = () => {
    new Promise((resolve, _) =>
      resolve(api.get(`/associate-monthly-billing`))
    ).then(({ data }: any) => setAssociateMonthlyBillings(data));
  };

  const getLastControlNumber = () => {
    return new Promise((resolve, _) =>
      resolve(api.get(`/associate-monthly-billing/getLastControlNumber`))
    ).then(({ data }: any) => data);
  };

  const getAssociate = () => {
    new Promise((resolve, _) => resolve(api.get(`/associates`))).then(
      ({ data }: any) => setAssociates(data)
    );
  };

  const getVehicles = () => {
    new Promise((resolve, _) => resolve(api.get(`/vehicles`))).then(
      ({ data }: any) => setVehicles(data)
    );
  };

  const getSuppliers = () => {
    new Promise((resolve, _) => resolve(api.get(`/supplier`))).then(
      ({ data }: any) => setSuppliers(data)
    );
  };

  const handleAdd = async () => {
    const { controlNumber } = await getLastControlNumber();

    new Promise((resolve, _) => {
      resolve(
        api.post(`/associate-monthly-billing`, {
          controlNumber: controlNumber,
        })
      );
    }).then(({ data }: any) => {
      setAssociateMonthlyBilling((prev: any) => ({
        ...prev,
        ...data,
        controlNumber: controlNumber,
      }));
      setAction("add");
      setOpen(true);
    });
  };

  const onClose = () => {
    setOpen(false);
    setAssociateMonthlyBilling({});
    new Promise((resolve, _) => resolve(getData()));
  };

  const handleEdit = ({ data }: any) => {
    console.log(data);
    new Promise((resolve, _) => {
      resolve(
        api.put(`/associate-monthly-billing/${data?._id}`, {
          finalized: false,
        })
      );
    })
      .then((response: any) => {
        setAction("edit");
        setAssociateMonthlyBilling({
          ...response.data,
          associateCode: data?.associate?.code,
        });
        setOpen(true);
      })
      .catch(() => {
        message.error("Falha ao reabrir o Fechamento. Tente novamente");
      });
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/associate-monthly-billing/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onFinalize = (content: any) => {
    new Promise((resolve, _) => {
      resolve(api.put(`/associate-monthly-billing/${content?._id}`, content));
    })
      .then(({ data }: any) => {
        setAssociateMonthlyBilling({});
        setOpen(false);
        getData();
        message.success("Fechamento finalizado com sucesso");
      })
      .catch(() => {
        message.error("Falha ao finalizar o Fechamento. Tente novamente");
      });
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      resolve(api.put(`/associate-monthly-billing/${content?._id}`, content));
    }).then(({ data }: any) => {
      setAssociateMonthlyBilling(data);
    });
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (![null, undefined].includes(content[key])) {
        body = {
          ...body,
          [key]: ["endDate", "startDate"].includes(key)
            ? dayjs(content[key]).format("YYYY-MM-DD")
            : content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) =>
        resolve(api.get(`/associate-monthly-billing/search?${queryString}`))
      )
        .then(({ data }: any) => setAssociateMonthlyBillings(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) =>
        resolve(api.get(`/associate-monthly-billing`))
      )
        .then(({ data }: any) => setAssociateMonthlyBillings(data))
        .finally(() => setLoading(false));
    }
  };

  const onDownload = (content: any) => {
    setLoading(true);
    new Promise((resolve, _) => {
      resolve(
        api.get(`/associate-monthly-billing/download/${content?._id}`, {
          responseType: "blob",
        })
      );
    })
      .then(({ data }: any) => {
        // Cria um Blob a partir do buffer
        const blob = new Blob([data], { type: "application/pdf" });

        // Cria um URL para o Blob
        const url = URL.createObjectURL(blob);

        // Cria um link para o download
        const link = document.createElement("a");
        link.href = url;
        link.download = `${content?._id}.pdf`; // Nome do arquivo para o download

        // Simula um clique no link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Libera o URL do Blob
        URL.revokeObjectURL(url);
      })
      .catch(() =>
        message.error("Falha ao realizar o download. Tente novamente")
      )
      .finally(() => setLoading(false));
  };

  const onSendMail = (content: any) => {
    setLoading(true);
    new Promise((resolve, _) => {
      resolve(
        api.post(`/associate-monthly-billing/sendEmail/${content?._id}`, {
          responseType: "blob",
        })
      );
    })
      .then(()=>message.success("Email enviado com sucesso"))
      .catch((e) => {
        message.error(`Falha ao enviar o email. ${(e?.response?.data?.message || "Tente novamente")}`);
      }).finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getAssociate(),
      getVehicles(),
      getSuppliers(),
      getData(),
    ]).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const columns: ColumnsType<[]> = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",

      render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
      align: "center",
    },
    {
      title: "Número de Controle",
      dataIndex: "controlNumber",
      key: "controlNumber",

      align: "center",
      onCell: () => ({ style: { flex: 1 } }),
    },
    {
      title: "Associado",
      dataIndex: "associate",
      key: "associate",

      render: (value, record) => value?.name,
      onCell: () => ({ style: { flex: 1 } }),
    },
    {
      title: "Valor Desconto",
      width: "10%",
      dataIndex: "details",
      key: "details",
      render: (value, record) => (
        <Tag color="blue">
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(
            (value || []).reduce(
              (prev: any, curr: any) => prev + curr?.value,
              0
            )
          )}
        </Tag>
      ),
    },
    {
      title: "Data Criação",
      dataIndex: "createdAt",
      key: "createdAt",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value) => value && dayjs(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Data Última Alteração",
      dataIndex: "updatedAt",
      key: "updatedAt",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value) => value && dayjs(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Usuário",
      dataIndex: "user",
      key: "user",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value) => value?.name,
    },
    {
      title: "Situação",
      dataIndex: "finalized",
      key: "finalized",
      onCell: () => ({ style: { flex: 1 } }),

      render: (value, record) => (
        <Tag color={value ? "green" : "orange"}>
          {value ? "Finalizado" : "Em andamento"}
        </Tag>
      ),
    },
    {
      onCell: () => ({ style: { flex: 1 } }),

      render: (value, record: any) => (
        <span style={{ display: "flex", gap: "2px" }}>
          {record?.finalized && (
            <>
              <Tooltip>
                <Button
                  size="small"
                  onClick={() => onSendMail(value)}
                  icon={<MdEmail />}
                >
                  Email
                </Button>
              </Tooltip>
              <Tooltip>
                <Button
                  size="small"
                  onClick={() => onDownload(value)}
                  icon={<PrinterFilled />}
                >
                  Imprimir
                </Button>
              </Tooltip>
            </>
          )}
          <ActionGrid
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            roles={roles}
            value={value}
          />
        </span>
      ),
    },
  ];

  return (
    <Container>
      <Spin spinning={loading} tip="Carregando...">
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <PageHeader
              title="Fechamento de Associados"
              onClickAdd={handleAdd}
              roles={roles}
              loading={loading}
            />
            <Divider dashed />
            <SearchForm onFinish={onSearch} />
          </Col>

          <Col span={24}>
            <Divider dashed />
          </Col>
          <Col span={24}>
            <Table
              rowKey={"_id"}
              bordered
              loading={loading}
              dataSource={associateMonthlyBillings}
              columns={columns}
              size="small"
              style={{ width: "100%", overflow: "scroll" }}
            />
          </Col>
        </Row>
      </Spin>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={onClose}
        children={
          <CreateOrEditForm
            onFinish={onSubmit}
            onFinalizeInsert={onFinalize}
            dataSource={{
              associateMonthlyBilling,
              associates,
              vehicles,
              suppliers,
            }}
          />
        }
        width={860}
      />
    </Container>
  );
};
