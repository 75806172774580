import { Button, Col, Divider, Input, message, Row, Space, Table } from "antd";
import { Container } from "../../components/container";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { ColumnsType } from "antd/es/table";
import Drawer from "../../components/drawer";
import { SearchForm } from "../../forms/employees/SearchForm";
import { CreateOrEditForm } from "../../forms/employees/CreateOrEditForm";
import { PageHeader } from "../../components/page-header";
import { ActionGrid } from "../../components/action-grid";
import { useSecurity } from "../../hooks/useSecurity";
import dayjs from "dayjs";

import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { exportExcel } from "../../utils/export-excel";

export const Employees: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [employee, setEmployee] = useState({});
  const [employees, setEmployees] = useState<any[]>([]);
  const [positions, setPositions] = useState<any[]>([]);
  const [associates, setAssociates] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();
  const [params, setParams] = useState<any>({
    name: "",
    code: "",
    registerNumber: "",
    position: "",
    associate: "",
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [form] = useForm();
  const { getPermissions } = useSecurity();

  const getData = (filter?: any) => {
    setLoading(true);
    new Promise((resolve, _) =>
      resolve(
        api.get(`/employees`, {
          params: filter,
        })
      )
    )
      .then(({ data }: any) => setEmployees(data))
      .finally(() => setLoading(false));
  };

  const getAssociates = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/associates`)))
      .then(({ data }: any) => setAssociates(data))
      .finally(() => setLoading(false));
  };

  const getPositions = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/positions`)))
      .then(({ data }: any) => setPositions(data))
      .finally(() => setLoading(false));
  };

  const onExportExcel = () => {
    setLoading(true);
    try {
      exportExcel(employees, "Funcionarios");
      message.info("Exportação em andamento...");
    } catch (error) {
      message.error("Falha ao exportar. Tente novamente");
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setAction("add");
    setEmployee({});
    setOpen(true);
  };

  const handleEdit = ({ data }: any) => {
    setAction("edit");
    setEmployee(data);
    setOpen(true);
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/employees/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(
          api.put(`/employees/${content?._id}`, {
            ...content,
            ...(content?.driveLicenseValidity
              ? {
                  driveLicenseValidity: dayjs(
                    content?.driveLicenseValidity,
                    "DD/MM/YYYY"
                  ).format("YYYY-MM-DD"),
                }
              : {}),
          })
        );
      } else {
        resolve(
          api.post(`/employees`, {
            ...content,
            ...(content?.driveLicenseValidity
              ? {
                  driveLicenseValidity: dayjs(
                    content?.driveLicenseValidity,
                    "DD/MM/YYYY"
                  ).format("YYYY-MM-DD"),
                }
              : {}),
          })
        );
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getData()));
      setEmployee({});
      setOpen(false);
    });
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
    new Promise((resolve, _) => resolve(getPositions()));
    new Promise((resolve, _) => resolve(getAssociates()));
  }, []);

  const onSearch = () => {
    setParams((prev: any) => ({ ...prev, ...form.getFieldsValue() }));
  };

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any, confirm: any) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const onReset = () => {
    setLoading(true);
    form.resetFields();
    setParams({
      fullname: "",
      code: "",
      registerNumber: "",
      position: "",
      associate: "",
    });
    setLoading(false);
  };

  useEffect(() => {
    getData(params);
  }, [params]);

  const columns: ColumnsType<[]> = [
    {
      title: "Nome",
      dataIndex: "fullname",
      key: "fullname",
      filterSearch: true,
      render: (text: any) => (searchedColumn === "fullname" ? text : text),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Pesquisar`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, "fullname")
              }
              style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                onClick={() => handleReset(clearFilters, confirm)}
                size="small"
                style={{ width: 90 }}
              >
                Resetar
              </Button>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, "fullname")}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Pesquisar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value: any, record: any) =>
        record["fullname"]
          ? record["fullname"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
    },

    {
      title: "CPF",
      dataIndex: "code",
      key: "code",
      filterSearch: true,
      render: (text: any) => (searchedColumn === "code" ? text : text),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Pesquisar`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm, "code")}
              style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                onClick={() => handleReset(clearFilters, confirm)}
                size="small"
                style={{ width: 90 }}
              >
                Resetar
              </Button>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, "code")}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Pesquisar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value: any, record: any) =>
        record["code"]
          ? record["code"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
    },
    {
      title: "RG",
      dataIndex: "registerNumber",
      key: "registerNumber",
      filterSearch: true,
      render: (text: any) =>
        searchedColumn === "registerNumber" ? text : text,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Pesquisar`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, "registerNumber")
              }
              style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                onClick={() => handleReset(clearFilters, confirm)}
                size="small"
                style={{ width: 90 }}
              >
                Resetar
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys, confirm, "registerNumber")
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Pesquisar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value: any, record: any) =>
        record["registerNumber"]
          ? record["registerNumber"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
    },
    {
      title: "Cargo",
      dataIndex: "position",
      key: "position",
      render: (value: any) => value?.description ?? "",
      filterSearch: true,
      onFilter: (value, record: any) => record?.position?._id === value,
      filters: positions.map((e: any) => ({
        text: e?.description,
        value: e?._id,
      })),
    },
    {
      title: "CNH",
      dataIndex: "driveLicense",
      key: "driveLicense",
      filterSearch: true,
      render: (text: any) => (searchedColumn === "driveLicense" ? text : text),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Pesquisar`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, "driveLicense")
              }
              style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                onClick={() => handleReset(clearFilters, confirm)}
                size="small"
                style={{ width: 90 }}
              >
                Resetar
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys, confirm, "driveLicense")
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Pesquisar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value: any, record: any) =>
        record["code"]
          ? record["code"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
    },
    {
      title: "Telefone",
      dataIndex: "cellPhone",
      key: "cellPhone",
    },
    {
      title: "Associado",
      dataIndex: "associate",
      key: "associate",
      render: (value: any) => value?.name ?? "",
      filterSearch: true,
      onFilter: (value, record: any) => record?.associate?._id === value,
      filters: associates.map((e: any) => ({
        text: e?.name,
        value: e?._id,
      })),
    },
    {
      render: (value, record) => (
        <ActionGrid
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          roles={roles}
          value={value}
        />
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <PageHeader
            title="Funcionários"
            onClickAdd={handleAdd}
            onExportExcel={onExportExcel}
            roles={roles}
          />
          <SearchForm
            form={form}
            onFinish={onSearch}
            onReset={onReset}
            data={{ associates }}
          />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={employees}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        children={
          <CreateOrEditForm
            onFinish={onSubmit}
            data={{ employee, positions, associates }}
          />
        }
      />
    </Container>
  );
};
